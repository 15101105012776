import 'babel-plugin-transform-regenerator';

var v_server;
var v_sessionId;
var v_startup;
var pGatekeeper;
var pipes;
var calls;
var delay;
var timeout;
window.visore = false;
var json_server = false;
var json_session = false;
var keepAliveInterval = false;
var st_keep = false;
var sessionTimer = 300;
var timeoutHandle = 0;
var xmlhttp;
var level = 0;
var clk = false;
var p_alpha = -15;
var p_beta = 20;
var sessionTimerKeepAlive = false;

/**
 * Manage frame parent resize
 * @returns {undefined}
 */
window.iframeResizePipe = function() {
    // What's the page height?
    var height = document.body.scrollHeight;

    // Going to 'pipe' the data to the parent through the helpframe..
    var pipe = document.getElementById('helpframe');

    // Cachebuster a precaution here to stop browser caching interfering
    if(pipe) {
        pipe.src = 'https://www.kristalia.it/iframe_helper.html?height='+height+'&cacheb='+Math.random();
    }
 }

/**
 * Nuona funzione per la connessione al server con https
 * @returns {provaGate.indexAnonym$0}
 */
async function provaGate(){
    
    if(json_server == false || json_session == false) {
        const url = "https://eaiws-server.pcon-solutions.com/v2/session/5dea703d856c1";
        const response = await fetch(url);
        const { server, sessionId, keepAliveInterval } = await response.json();

        json_server = server;	
        json_session = sessionId;	
        st_keep=false;
        pGatekeeper="loadGate";
        
        // remove trailing slash from retrieved url
        json_server = json_server.substring(0,json_server.length-1);
        
        return {server, sessionId, keepAliveInterval};
    } else {
        return {json_server, json_session, keepAliveInterval};
    }
};

function copyToClipboard(text) {
    window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
}

function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function URLToArray(url) {
        var request = {};
        var arr = [];
        var pairs = url.substring(url.indexOf('?') + 1).split('&');
        for (var i = 0; i < pairs.length; i++) {
          var pair = pairs[i].split('=');

          //check we have an array here - add array numeric indexes so the key elem[] is not identical.
          if(endsWith(decodeURIComponent(pair[0]), '[]') ) {
              var arrName = decodeURIComponent(pair[0]).substring(0, decodeURIComponent(pair[0]).length - 2);
              if(!(arrName in arr)) {
                  arr.push(arrName);
                  arr[arrName] = [];
              }

              arr[arrName].push(decodeURIComponent(pair[1]));
              request[arrName] = arr[arrName];
          } else {
            request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
          }
        }
        return request;
    }

window.loadXMLDoc = function(str) {
    window.clearTimeout(sessionTimerKeepAlive); // cancella il timer keepalive
    
    pipes = 0;

    // var s = document.getElementById('i_server');
    // var v_server = s.value;
    
    provaGate().then(server => {
        v_server = json_server;
        v_sessionId = json_session;
        
        document.getElementById('i_server').value = v_server;
        document.getElementById('i_sessionId').value = v_sessionId;
            
        var v_sessionId = document.getElementById('i_sessionId').value;
        var v_startup = document.getElementById('i_startup').value;
        var v_locale = document.getElementById('i_locale').value;
        var v_path = document.getElementById('i_path').value;
        var original_path = document.getElementById('original_path').value;
        var v_articlePackageId = document.getElementById('i_articlePackageId').value;
        var v_baseArticleNumber = document.getElementById('i_baseArticleNumber').value;
        var v_catalogId = document.getElementById('i_catalogId').value;
        var v_catalogPackageId = document.getElementById('i_catalogPackageId').value;
        var v_variantCode = document.getElementById('i_variantCode').value;

        var v_height = document.getElementById('i_height').value;
        var v_width = document.getElementById('i_width').value;
        var v_format = document.getElementById('i_format').value;
        var v_resample = document.getElementById('i_resample').value;
        var v_quality = document.getElementById('i_quality').value;

        var v_ambient = document.getElementById('i_ambient').value;
        var v_ambient = hexToRgb('i_s_ambient', v_ambient);

        var v_background = document.getElementById('i_background').value;
        var v_renderMode = document.getElementById('i_renderMode').value;
        var v_fadeBorder = document.getElementById('i_fadeBorder').value;
        var v_shadowPlane_border = document.getElementById('i_shadowPlane_border').value;
        var v_shadowPlane_color = document.getElementById('i_shadowPlane_color').value;
        var v_shadowPlane_smoothness = document.getElementById('i_shadowPlane_smoothness').value;
        var v_shadowPlane_mirror = document.getElementById('i_shadowPlane_mirror').value;
        var v_shadowPlane_colorMode = document.getElementById('i_shadowPlane_colorMode').value;
        var v_shadowPlane_filter = document.getElementById('i_shadowPlane_filter').value;
        var v_shadowPlane_mapSize = document.getElementById('i_shadowPlane_mapSize').value;
        var v_shadowPlane_maxHeight = document.getElementById('i_shadowPlane_maxHeight').value;

        var v_projectionMode = document.getElementById('i_projectionMode').value;
        var v_zoom = document.getElementById('i_zoom').value;
        var v_alpha = document.getElementById('i_alpha').value;
        var v_beta = document.getElementById('i_beta').value;
        var v_scale = document.getElementById('i_scale').value;
        var v_margin = document.getElementById('i_margin').value;
        var v_extraConfigure = document.getElementById('i_extraConfigure').value;

        var v_propclass = document.getElementById('i_propclass').value;
        var v_propname = document.getElementById('i_propname').value;
        var v_propvalue = document.getElementById('i_propvalue').value;
        var v_itemid = document.getElementById('i_itemid').value;
        var v_mainitemid = document.getElementById('i__MainItemId').value;
        var v_type = document.getElementById('i_type').value;

        //document.getElementById('i_propclass').value = '';
        //document.getElementById('i_propname').value = '';
        //document.getElementById('i_propvalue').value = '';
        //document.getElementById('i_itemid').value = '';

        var v_hideSubArticles = document.getElementById("i_hideSubArticles").checked;
        var v_antialias = document.getElementById("i_antialias").checked;
        var v_outline = document.getElementById("i_outline").checked;
        var v_shadowPlane = document.getElementById("i_shadowPlane").checked;
        var v_enableShaders = document.getElementById("i_enableShaders").checked;
        var v_autoSmooth = document.getElementById("i_autoSmooth").checked;

        var insMode = true;
        var createScaled = document.getElementById("i_c_scale").checked;

        var v_manID = document.getElementById('i_manID').value;
        var v_progID = document.getElementById('i_progID').value;
        var v_BAN = document.getElementById('i_BAN').value;
        var v_test = document.getElementById('txt_Search').value;

        var str_path = document.getElementById('i_path').value;
        for (var i = 0; i < str_path.length; i++) {
            if (str_path.charAt(i) == '|') {
                pipes++;
            }
        }
        var path_01 = document.getElementById('i_path').value;

        if (pipes == 0 && path_01 == "") {
            level = 0;
        } else if (pipes == 0 && path_01 != "") {
            level = 1;
        } else {
            level = pipes + 1;
        }

        var s_id = document.getElementById('i_sessionId').value;
        if (str == 'new') {
            document.getElementById('i_sessionId').value = "";
            v_itemid = '';
            document.getElementById('i_itemid').value = "";
            s_id = '';
        }

        var formQuery = '?';
        if(v_test) formQuery += 'test=' + v_test + '&';
        if(v_server) formQuery += 'server=' + v_server + '&';
        if(s_id) formQuery += 'sessionId=' + s_id + '&';
        if(v_startup) formQuery += 'startup=' + v_startup + '&';
        if(v_locale) formQuery += 'locale=' + v_locale + '&';
        if(v_path) formQuery += 'path=' + v_path + '&';
        if(original_path) formQuery += 'original_path=' + original_path + '&';
        if(v_articlePackageId) formQuery += 'articlePackageId=' + v_articlePackageId + '&';
        if(v_baseArticleNumber) formQuery += 'baseArticleNumber=' + v_baseArticleNumber + '&';
        if(v_catalogId) formQuery += 'catalogId=' + v_catalogId + '&';
        if(v_catalogPackageId) formQuery += 'catalogPackageId=' + v_catalogPackageId + '&';
        if(v_variantCode) formQuery += 'variantCode=' + v_variantCode + '&';
        if(v_height) formQuery += 'height=' + v_height + '&';
        if(v_width) formQuery += 'width=' + v_width + '&';
        if(v_format) formQuery += 'format=' + v_format + '&';
        if(v_resample) formQuery += 'resample=' + v_resample + '&';
        if(v_quality) formQuery += 'quality=' + v_quality + '&';
        if(v_ambient) formQuery += 'ambient=' + v_ambient + '&';
        if(v_background) formQuery += 'background=' + v_background + '&';
        if(v_renderMode) formQuery += 'renderMode=' + v_renderMode + '&';
        if(v_fadeBorder) formQuery += 'fadeBorder=' + v_fadeBorder + '&';
        if(v_shadowPlane_border) formQuery += 'shadowPlane_border=' + v_shadowPlane_border + '&';
        if(v_shadowPlane_color) formQuery += 'shadowPlane_color=' + v_shadowPlane_color + '&';
        if(v_shadowPlane_smoothness) formQuery += 'shadowPlane_smoothness=' + v_shadowPlane_smoothness + '&';
        if(v_shadowPlane_mirror) formQuery += 'shadowPlane_mirror=' + v_shadowPlane_mirror + '&';
        if(v_shadowPlane_colorMode) formQuery += 'shadowPlane_colorMode=' + v_shadowPlane_colorMode + '&';
        if(v_shadowPlane_filter) formQuery += 'shadowPlane_filter=' + v_shadowPlane_filter + '&';
        if(v_shadowPlane_mapSize) formQuery += 'shadowPlane_mapSize=' + v_shadowPlane_mapSize + '&';
        if(v_shadowPlane_maxHeight) formQuery += 'shadowPlane_maxHeight=' + v_shadowPlane_maxHeight + '&';
        if(v_projectionMode) formQuery += 'projectionMode=' + v_projectionMode + '&';
        if(v_zoom) formQuery += 'zoom=' + v_zoom + '&';
        if(v_alpha) formQuery += 'alpha=' + v_alpha + '&';
        if(v_beta) formQuery += 'beta=' + v_beta + '&';
        if(v_scale) formQuery += 'scale=' + v_scale + '&';
        if(v_margin) formQuery += 'margin=' + v_margin + '&';
        if(v_hideSubArticles) formQuery += 'hideSubArticles=' + v_hideSubArticles + '&';
        if(v_antialias) formQuery += 'antialias=' + v_antialias + '&';
        if(v_outline) formQuery += 'outline=' + v_outline + '&';
        if(v_shadowPlane) formQuery += 'shadowPlane=' + v_shadowPlane + '&';
        if(v_enableShaders) formQuery += 'enableShaders=' + v_enableShaders + '&';
        if(v_autoSmooth) formQuery += 'autoSmooth=' + v_autoSmooth + '&';
        if(insMode) formQuery += 'insertMode=' + insMode + '&';
        if(v_extraConfigure) formQuery += 'extraConfigure=' + v_extraConfigure + '&';
        if(level) formQuery += 'level=' + level + '&';
        if(createScaled) formQuery += 'createScaled=' + createScaled + '&';
        if(v_propclass) formQuery += 'propclass=' + v_propclass + '&';
        if(v_propname) formQuery += 'propname=' + v_propname + '&';
        if(v_propvalue) formQuery += 'propvalue=' + v_propvalue + '&';
        if(v_itemid) formQuery += 'itemid=' + v_itemid + '&';
        if(v_mainitemid) formQuery += 'mainitemid=' + v_mainitemid + '&';
        if(v_type) formQuery += 'itemtype=' + v_type + '&';

        if (str == 'keep') {
            formQuery += 'keep=1' + '&';
            st_keep = true;
        } else {
            formQuery += 'keep=0' + '&';
        }

        if (str == 'close') {
            formQuery += 'close=1' + '&';
            st_keep = true;
        } else {
            formQuery += 'close=0' + '&';
        }

        formQuery += 'manID=' + v_manID + '&';
        formQuery += 'progID=' + v_progID + '&';
        formQuery += 'BAN=' + v_BAN + '&';

        var ranNr = Math.floor(Math.random() * 10000000000000000001);
        // formQuery += 'nocache=' + ranNr;

        // var url = 'eaiws.php' + formQuery;



        var params = new URLSearchParams(formQuery);

        // Rimuovi i parametri con valore vuoto
        for (let [key, value] of params.entries()) {
            if (!value.trim()) {
                params.delete(key);
            }
        }
        // Costruisci la nuova query string
        var newQuery = params.toString();
        var url = 'eaiws.php' + (newQuery ? '?' + newQuery : '');
        var url = 'eaiws.php';
        console.log('url', url)



        xmlhttp = null;
        if (window.XMLHttpRequest)
        {// code for all new browsers
            xmlhttp = new XMLHttpRequest();
        } else if (window.ActiveXObject)
        {// code for IE5 and IE6
            xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        if (xmlhttp != null)
        {
            xmlhttp.onreadystatechange = state_Change;
            window.clearTimeout(timeoutHandle);
            // countdown(sessionTimer, 'cID');
            xmlhttp.open("POST", 'eaiws.php', true);
            xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

            xmlhttp.send(newQuery);
        } else
        {
            alert("Your browser does not support XMLHTTP.");
        }

        // entro 4 minuti, se non è stato lanciato loadXMLDoc, rinnova la sessione
        sessionTimerKeepAlive = window.setTimeout('loadXMLDoc()',240000);
        
        // resize parent iframe
        setTimeout("iframeResizePipe();",200);
    });
}

function state_Change() {
    if (xmlhttp.readyState == 4)
    {// 4 = "loaded"
        if (xmlhttp.status == 200)
        {// 200 = OK
            if (typeof (timeoutHandle) != "undefined") {
                window.clearTimeout(timeoutHandle);
                countdown(sessionTimer, 'cID');
            }
            if (!st_keep) {
                document.getElementById('response_inner').innerHTML = xmlhttp.responseText;
                if(document.getElementById('i_mainitemid')) {
                    var mainItemId = document.getElementById('i_mainitemid').value;
                    document.getElementById("i__MainItemId").value = mainItemId;
                }
            } else {
                //document.getElementById('response_inner').innerHTML = xmlhttp.responseText;
                var st_keep = false;
            }
        } else
        {
            alert("Problem retrieving XML data");
        }
    }
}

window.set = function(pAPI, pBAN, pCI, pCNK, pCPI, pIC, pIM, pNAM, pTYP, pVC, pSessionId) {
    var clk = true;
    document.getElementById('i_articlePackageId').value = pAPI;
    document.getElementById('i_baseArticleNumber').value = pBAN;
    document.getElementById('i_catalogId').value = pCI;
    document.getElementById('i_catalogPackageId').value = pCPI;
    document.getElementById('i_type').value = pTYP;
    var path = document.getElementById('i_path').value;

    if (path == '') {
        document.getElementById('i_path').value = pNAM;
    } else {
        var path_split = path.split("|");
        var path_split = path.split("|", (level));
        var path_new = path_split[0];
        for (var cnt = 1; cnt < level; cnt++) {
            path_new = path_new + "|";
            path_new = path_new + path_split[cnt];
        }
        if (pNAM != '<--') {
            document.getElementById('i_path').value = path_new + '|' + pNAM;
        } else {
            level--;
            var path_split = path.split("|", (level));
            var path_new = path_split[0];
            for (var cnt = 1; cnt < level; cnt++) {
                path_new = path_new + "|";
                path_new = path_split[cnt];
            }
            if (typeof (path_new) == "undefined") {
                path_new = "";
            }
            document.getElementById('i_path').value = path_new;
            document.getElementById('i_propclass').value = '';
            document.getElementById('i_propname').value = '';
            document.getElementById('i_propvalue').value = '';
            document.getElementById('i_itemid').value = '';
        }
    }
    document.getElementById('i_variantCode').value = pVC;
    document.getElementById('i_sessionId').value = pSessionId;

    loadXMLDoc();
    
    setTimeout("iframeResizePipe();",1200);
}

function reset_level() {
    level = 0;
    document.getElementById('i_sessionId').value = "";
    document.getElementById('i_path').value = "";
    document.getElementById('i_articlePackageId').value = "";
    document.getElementById('i_baseArticleNumber').value = "";
    document.getElementById('i_catalogId').value = "";
    document.getElementById('i_catalogPackageId').value = "";
    document.getElementById('i_variantCode').value = "";
    document.getElementById('i_propclass').value = '';
    document.getElementById('i_propname').value = '';
    document.getElementById('i_propvalue').value = '';
    document.getElementById('i_itemid').value = '';

    loadXMLDoc();
}

function msg(str) {
    alert(str);
}

function showValue(elemId, newValue) {
    document.getElementById(elemId).innerHTML = ' ' + newValue;
}

function hexToRgb(elemId, hex) {
    var t_hex = hex.replace("#", "");
    var bigint = parseInt(t_hex, 16);

    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    r = (r / 255).toFixed(2);
    g = (g / 255).toFixed(2);
    b = (b / 255).toFixed(2);

    document.getElementById(elemId).innerHTML = r + ' ' + g + ' ' + b;
    return r + ' ' + g + ' ' + b;
}

window.scaleOn = function(objCheckbox) {
    if (objCheckbox.checked) {
        document.getElementById('i_scale').disabled = false;
        document.getElementById('i_projectionMode').selectedIndex = 1;
        document.getElementById('i_format').selectedIndex = 1;

        p_alpha = document.getElementById('i_alpha').value;
        p_beta = document.getElementById('i_beta').value;

        document.getElementById('i_alpha').value = 0;
        document.getElementById('i_beta').value = 90;
        document.getElementById('i_alpha').step = 90;
        document.getElementById('i_beta').step = 90;

        showValue('i_s_alpha', document.getElementById('i_alpha').value);
        showValue('i_s_beta', document.getElementById('i_beta').value);
    } else {
        document.getElementById('i_scale').disabled = true;
        document.getElementById('i_projectionMode').selectedIndex = 0;
        document.getElementById('i_format').selectedIndex = 0;

        document.getElementById('i_alpha').step = 1;
        document.getElementById('i_beta').step = 1;
        document.getElementById('i_alpha').value = p_alpha;
        document.getElementById('i_beta').value = p_beta;

        showValue('i_s_alpha', document.getElementById('i_alpha').value);
        showValue('i_s_beta', document.getElementById('i_beta').value);
    }
}

window.setProperty = function(pType, e, pPC, pPN, pPV, pII, pSI) {
    if (pType == 'click') {
        if (e == 13) {
            //alert(pType + ' ' + e + ' ' + pPC + ' ' + pPN + ' ' + pPV + ' ' + pII);
            document.getElementById('i_sessionId').value = pSI;
            document.getElementById('i_itemid').value = pII;
            document.getElementById('i_propclass').value = pPC;
            document.getElementById('i_propname').value = pPN;
            document.getElementById('i_propvalue').value = pPV;
            loadXMLDoc();
        }
    } else
    if (pType == 'select') {
        //alert(pType + ' ' + e + ' ' + pPC + ' ' + pPN + ' ' + pPV + ' ' + pII);
        document.getElementById('i_sessionId').value = pSI;
        document.getElementById('i_itemid').value = pII;
        document.getElementById('i_propclass').value = pPC;
        document.getElementById('i_propname').value = pPN;
        document.getElementById('i_propvalue').value = pPV;
        loadXMLDoc();
    }
}

window.setItem = function(pType, e, pII, pSI) {
    if (pType == 'select') {
        //alert(pType + ' ' + e + ' ' + pII + ' ' + pSI);
        document.getElementById('i_sessionId').value = pSI;
        document.getElementById('i_itemid').value = pII;
        document.getElementById('i_propclass').value = '';
        document.getElementById('i_propname').value = '';
        document.getElementById('i_propvalue').value = '';
        loadXMLDoc();
    }
}

window.visible = function(pDIV) {
    if (document.getElementById(pDIV).style.display == 'none') {
        document.getElementById(pDIV).style.display = 'block';
    } else
        document.getElementById(pDIV).style.display = 'none';

}

window.countdown = function(time, id) {
    //time brauchen wir später noch
    var t = time;

    //Tage berechnen
    var d = Math.floor(t / (60 * 60 * 24)) % 24;

    // Stunden berechnen
    var h = Math.floor(t / (60 * 60)) % 24;


    // Minuten berechnen
    // Sekunden durch 60 ergibt Minuten
    // Minuten gehen von 0-59
    //also Modulo 60 rechnen
    var m = Math.floor(t / 60) % 60;

    // Sekunden berechnen
    var s = t % 60;

    //Zeiten formatieren
    d = (d > 0) ? d + "d " : "";
    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;

    // Ausgabestring generieren
    var strZeit = d + h + ":" + m + ":" + s;

    // Falls der Countdown noch nicht zurückgezählt ist
    if (time > 0)
    {
        //Countdown-Funktion erneut aufrufen
        //diesmal mit einer Sekunde weniger
        timeoutHandle = window.setTimeout('countdown(' + --time + ',\'' + id + '\')', 1000);
    } else
    {
        //führe eine funktion aus oder refresh die seite
        //dieser Teil hier wird genau einmal ausgeführt und zwar
        //wenn die Zeit um ist.
        loadXMLDoc('keep');
        //strZeit = "Fertig";
    }
    // Ausgabestring in Tag mit id="id" schreiben
    document.getElementById(id).innerHTML = strZeit;
}


function closeit() {
    loadXMLDoc('close');
    return;
}

timeout = false, // holder for timeout id
delay = 1000, // delay after event is "complete" to run callback
calls = 0;

// window.resize callback function
window.rebuild = function() {
    // resize canvas
    if(visore != false) {
        var $webglcontainer = visore.mViewerContainer;
        var container_width = $webglcontainer.style.height;
        // var $canvas = $webglcontainer.querySelector('canvas');
        // $canvas.setAttribute('style','width:500px;outline:none;');
        
        visore.resizeWebglViewer();
    }

    setTimeout("iframeResizePipe();",1000);
    clearTimeout(timeout);
}

window.maximize3D = function() {
    // container product
    if($('#containerproduct').hasClass('container')) {
        $('#containerproduct').removeClass('container');
        $('#containerproduct').addClass('container-fluid');
    } else {
        $('#containerproduct').removeClass('container-fluid');
        $('#containerproduct').addClass('container');        
    }
    
    // container canvas
    if($('#containercanvas').hasClass('col-md-6')) {
        $('#containercanvas').removeClass('col-md-6');
    } else {
        $('#containercanvas').addClass('col-md-6');        
    }
    
    // container properties
    if($('#containerproperties').hasClass('d-none')) {
        $('#containerproperties').removeClass('d-none');
    } else {
        $('#containerproperties').addClass('d-none');
    }
    
    visore.resizeWebglViewer();
}

   var download = [];
    $('a.force-download, .force-download a').each(function(){
        // Collect info
        var $this = $(this),
            $href = $this.attr('href'),
            $split = $href.split('/'),
            $name = document.title.replace(/[\W_]/gi, '-').replace(/-{2,}/g, '-'); // get title and clean it for the URL

        // Get filename from URL
        if($split[($split.length-1)])
        {
            $tmp = $split[($split.length-1)];
            $tmp = $tmp.split('.');
            $name = $tmp[0].replace(/[\W_]/gi, '-').replace(/-{2,}/g, '-');
        }

        // If name already exists, put timestamp there
        if($.inArray($name, download) > -1)
        {
            $name = $name + '-' + Date.now().replace(/[\W]/gi, '-');
        }

        $(this).attr("download", $name);
        download.push($name);
    });

// Canvas top
 window.topWebGL = function() {

    var rel = parseFloat($('#topWebGL').attr('data-rel'));
    // rel = parseFloat(visore.mView.cameraControl.position.z);
    
    // direzione
    if(rel > 0) {
        rel = -1;
    } else {
        rel = 1;
    }
    $('#topWebGL').attr('data-rel',rel);

    window.visore.mView.cameraControl.setPosition(new BABYLON.Vector3(0, rel, -.1));
    window.visore.mView.cameraControl.zoomToFitElements(null, .2);
    window.visore.mView.camera.getViewMatrix(!0);
    window.visore.mWcfApp.viewer.requestRenderFrame();
};

// window.resize event listener
window.addEventListener('resize', function() {
    // clear the timeout
    clearTimeout(timeout);
    // start timing for event "completion"
    timeout = setTimeout(rebuild, delay);
});


    


window.onbeforeunload = closeit;


document.onkeydown = function (event) {
    if (event.ctrlKey && event.keyCode == 8) {
        event.cancelBubble = true;
        event.returnValue = false;
        //alert("Sie haben BACKSPACE gedrückt");

        level--;
        var path_split = path.split("|", (level));
        var path_new = path_split[0];
        for (var cnt = 1; cnt < level; cnt++) {
            path_new = path_new + "|";
            path_new = path_split[cnt];
        }
        if (typeof (path_new) == "undefined") {
            path_new = "";
        }
        document.getElementById('i_path').value = path_new;
        document.getElementById('i_propclass').value = '';
        document.getElementById('i_propname').value = '';
        document.getElementById('i_propvalue').value = '';
        document.getElementById('i_itemid').value = '';

        loadXMLDoc();
        
        setTimeout("iframeResizePipe();",200);

    }
    return event.returnValue;
}

window.view = function()
{
    var s = document.getElementById('i_server');
    var v_server = s.value;
    var v_sessionId = document.getElementById('i_sessionId').value;
    var v_itemid = document.getElementById('i__MainItemId').value;
    window.visore = new egr.ocf.Viewer('webgl/', document.getElementById("webgl"));
    window.visore.connectEAIWSSession(v_server, v_sessionId);
    window.visore.createArticleById(v_itemid);

    // resize parent iframe
    setTimeout("rebuild();",200);
}

class Module {
    constructor() {
        
    }
};

export default new Module();